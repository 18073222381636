import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

const Image = styled(Plaatjie)`
  border-radius: 15px;
  overflow: hidden;
  transform: translateZ(0);

  @media (min-width: 992px) {
    height: 370px;
  }

  @media (max-width: 567px) {
    max-width: 300px;
    height: 200px;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 2;
`

const Information = styled.div`
  background-color: ${({ theme }) => theme.color.dark};
  position: relative;
  z-index: 1;

  @media (min-width: 992px) {
    top: -50px;
  }

  @media (max-width: 991px) {
    top: -30px;
  }

  @media (max-width: 767px) {
    top: 0;
  }
`

const InformationContent = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};
`

interface InfoProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Info
}

const Info: React.FC<InfoProps> = ({ fields }) => (
  <section>
    <Content className="container pt-5 mt-lg-5">
      <div className="row">
        <div className="col-12 col-sm-5 col-md-6 pe-md-5 d-flex justify-content-center">
          <Image
            image={fields?.image}
            alt={fields?.link?.title || 'VT Tuinen'}
          />
        </div>
        <div className="col-12 col-sm-7 col-md-6 px-lg-5 d-flex align-items-center mt-4 mt-sm-0">
          <ParseContent content={fields.description} />
        </div>
      </div>
    </Content>
    <Information>
      <div className="container py-5 mb-lg-5 mt-md-0 mt-4">
        <div className="row pt-md-5">
          <div className="col-sm-7 py-lg-4 ps-md-5">
            <InformationContent
              className="ps-lg-5"
              content={fields.information}
            />
          </div>
          <div className="col-sm-5 d-flex justify-content-sm-end justify-content-center align-items-center pe-md-5 mt-4 mt-sm-0">
            <div className="pe-lg-5">
              <ButtonPrimary to={fields?.link?.url || '#'}>
                {fields?.link?.title}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </Information>
  </section>
)

export default Info
